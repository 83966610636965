.footer {
  display: grid;
  grid-template-columns: 10% 1fr 1fr 1fr;

  margin-top: 40px;
  background: linear-gradient(
    to left,
    var(--mainColor) 0%,
    var(--mainColor) 40%,
    transparent
  );
  justify-items: center;
  align-items: center;
}

.footer__logo {
  height: 9vh;
}

.footer__patreonLogo {
  height: 25px;
  margin-left: 3px;
}

svg {
  vertical-align: middle;
}

img {
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  .footer {
    grid-template-columns: 1fr 1fr;
  }
}
