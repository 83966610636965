.lessonCard__grades,
.lessonCard__exams {
  display: flex;
  align-items: center;
}

.lessonCard__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.lessonCard__grades > a {
  display: flex;
  vertical-align: middle;
  align-items: center;
  padding: 0px 5px;
}

.lessonCard__header:hover {
  font-weight: 700;
  text-shadow: 0px 0px 0px #dddd, 2px 2px 0px #dddd, 3px 3px 0px #dddd;
}

.lessonCard__header > img {
  width: 3rem;
}

.lessonCard__header > h4 {
  margin-left: 0.5em;
}

.lessonCard__gradeLink {
  width: 100%;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  padding: 10px;
  transition: background-position 275ms ease;
}

.lessonCard__gradeLink:after {
  content: "";
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

.lessonCard__gradeLink:hover:after,
.lessonCard__examLink:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.lessonCard__examLink {
  margin: 5px 0px 5px 5px;
  border: 1px solid grey;
  border-radius: 3px;
  padding: 1px;
}

.lessonCard__types {
  margin-left: 5px;
}
