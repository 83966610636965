.subject {
  text-align: center;
  zoom: 0.9;
}

.subject__types {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 3rem;
}

.subject__typesName {
  width: 170px;
  height: 100px;
  border: 1px solid grey;
  border-radius: 50%;
  line-height: 2rem;
  margin: auto;
  line-height: 90px;
  background-color: deepskyblue;
  cursor: pointer;
}

.subject__typesName > h3 {
  overflow-x: hidden;
  white-space: nowrap;
}

.subject__typesDescription {
  margin-top: 10px;
}

.subject__typesName:hover {
  background: linear-gradient(
    var(--mainColor) 0%,
    var(--mainColor) 40%,
    transparent
  );
  transition: all cubic-bezier(0.86, 0, 0.07, 1);
}

@media only screen and (max-width: 768px) {
  .subject__types {
    display: flex;
    flex-direction: column;
  }
}
