* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Dancing Script", cursive;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: rgb(38, 97, 245);
}

a:hover {
  text-shadow: 0px 0px 0px #dddd, 2px 2px 0px #dddd, 3px 3px 0px #dddd;
}

a:active {
  color: cornflowerblue;
}
