.app {
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.body_app {
  height: 100vh;
}

@media only screen and (max-width: 768px) {
  .body_app {
    height: unset;
  }
}
