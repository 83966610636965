.donation {
  text-align: center;
  vertical-align: middle;
}

.donation__paypal {
  margin-top: 40px;
  margin-bottom: 20px;
}

.donation__paypalButton {
  width: 200px;
}

.donation__patreon {
  margin-top: 20px;
}
