:root {
  --mainColor: deepskyblue;
  --test: red;
}

.topic {
  display: grid;
  height: 70vh;
  grid-template-columns: 1fr 1fr;
}

.topic__left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topic__right h1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.topic__levels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60%;
}

.topic_level {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.topic__video {
  border-radius: 20px;
}

.topic_level:hover {
  background: linear-gradient(
    to right,
    var(--mainColor) 0%,
    var(--mainColor) 5px,
    transparent
  );
}
