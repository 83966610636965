.overlay {
  text-align: center;
  position: relative;
  border-radius: 0 0 70% 70% / 50%;
  padding: 10px;
  text-shadow: 1px 1px 1px #333;
  background: linear-gradient(
    to right,
    var(--mainColor) 0%,
    var(--mainColor) 40%,
    transparent
  );
}

.overlay__header {
  font-size: 40px;
  margin-bottom: 10px;
}

body {
  font-family: "Roboto Slab", serif;

  margin: 0;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-size: 5vw;
  font-weight: 100;
}

body {
  font-family: "Roboto Slab", serif;
  margin: 0;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-size: 5vw;
  font-weight: 100;
}
