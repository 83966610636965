.login {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login__container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid lightgray;
  width: 300px;
  margin-top: 20px;
}

.login__container > h1 {
  text-align: center;
}

.login__container > form > h5 {
  margin-bottom: 5px;
}

.login__container > form > input {
  height: 30px;
  margin-bottom: 10px;
  background-color: white;
  width: 98%;
}

.login__container > form > button {
  font-size: 22px;
  color: black;
  background-color: orange;
  border: 1px solid;
  background-color: deepskyblue;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  width: 100%;
  height: 30px;
}

.login__container > form > button:hover {
  color: white;
  transform: scale(1.1);
}

.login__container > p {
  margin-top: 15px;
  font-size: 15px;
}

.login__registerLink {
  text-align: center;
}

.login__container .login__registerLink > button {
  font-size: 20px;
  color: black;
  border: 1px solid;
  border-color: #a88734 #9c7e31 #846a29;
  border-radius: 2px;
  margin-top: 15px;
  cursor: pointer;
}

.login__container .login__registerLink > button:hover {
  transform: scale(1.1);
}
