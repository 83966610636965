.body__title {
  text-align: center;
  padding: 15px;
}

.body__lessons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  margin-top: 3rem;
}

.body__videos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.body__videos > div {
  width: 33vw;
  padding: 25px;
}

@media only screen and (max-width: 768px) {
  .body__lessons {
    display: flex;
    flex-direction: column;
  }

  .body__videos {
    display: flex;
    flex-direction: column;
  }

  .body__videos > div {
    width: 100vw;
    padding: 0px;
  }
}
